import * as React from "react";
import { Heading } from 'paypo-ui/components';

import Seo from "../../components/seo";

import CookieModal from "../../components/organisms/CookieModal";

const NotFoundPage = () => (
  <div>
    <Seo title="404: Not Found" />

    <Heading variant='H1'>
      404: Strona nie odnaleziona
    </Heading>

    <CookieModal />
  </div>
);

export default NotFoundPage;
